import {
  getPropsFromElement
} from '../utilities/dom.js';

import {
  uniqueId
} from '../utilities/misc.js';

/**
 * A component that can be used to toggle the visibility of complementary
 * content.
 *
 * @version 1.0.0
 */
class DisclosureButton {
  /**
   * The default options used by the DisclosureButton component.
   *
   * @return {Object}
   */
  static get Defaults () {
    return {
      expanded: false,
      closeOnEsc: true,
      closeOnClickOutside: true
    };
  };

  /**
   * Create a new instance of the DisclosureButton component.
   *
   * @param  {HTMLElement} button The element representing the toggle button.
   * @param  {Object} options Additional options.
   */
  constructor (button, options = {}) {
    this.options = Object.assign({}, DisclosureButton.Defaults, options);

    this.button = button;
    this.content = button.hasAttribute('aria-controls')
      ? document.getElementById(button.getAttribute('aria-controls'))
      : button.nextElementSibling;

    if (!this.content) {
      throw new Error('DisclosureButton: Cannot find the target element');
    }

    if (this.button.tagName !== 'BUTTON') {
      throw new Error('DisclosureButton: A disclosure button should use an HTML element of type `button`');
    }

    this.setup();
    this.bind();
  }

  /**
   * Determine whether the disclosure element is currently expanded.
   *
   * @return {boolean}
   */
  get isExpanded () {
    return this.button.getAttribute('aria-expanded') === 'true';
  }

  /**
   * Setup the component instance.
   *
   * @return {void}
   */
  setup () {
    this.button.id = this.button.id || uniqueId('disclosure-button-');
    this.button.hidden = false;

    this.content.id = this.content.id || uniqueId('disclosure-content-');
    this.content.hidden = !this.options.expanded;

    this.button.setAttribute('role', 'button');
    this.button.setAttribute('aria-controls', this.content.id);
    this.button.setAttribute('aria-expanded', this.options.expanded);

    this.content.setAttribute('aria-labelledby', this.button.id);
  }

  /**
   * Dispose the component instance.
   *
   * @return {void}
   */
  dispose () {
    this.button.hidden = true;
    this.content.hidden = false;

    this.button.removeAttribute('aria-controls');
    this.button.removeAttribute('aria-expanded');

    this.content.removeAttribute('aria-labbeledby');
  }

  /**
   * Register event listeners.
   *
   * @return {void}
   */
  bind () {
    document.addEventListener('keyup', this);
    document.addEventListener('click', this);
  }

  /**
   * Remove previously registered event listeners.
   *
   * @return {void}
   */
  unbind () {
    document.addEventListener('keyup', this);
    document.removeEventListener('click', this);
  }

  /**
   * Expand the target element.
   *
   * @return {void}
   */
  expand () {
    this.button.classList.add('is-active');
    this.button.setAttribute('aria-expanded', true);
    this.content.hidden = false;
  }

  /**
   * Collapse the target element.
   *
   * @return {void}
   */
  collapse () {
    this.button.classList.remove('is-active');
    this.button.setAttribute('aria-expanded', false);
    this.content.hidden = true;
  }

  /**
   * Toggle the visibility of the target element.
   *
   * @return {void}
   */
  toggle () {
    if (this.isExpanded) {
      this.collapse();
    } else {
      this.expand();
    }
  }

  /**
   * Handle a event.
   *
   * @param  {Event} event Additional event arguments.
   * @return {boolean?}
   */
  handleEvent (event) {
    switch (event.type) {
      case 'click':
        return this.handleClick(event);
      case 'keyup':
        return this.handleKeyup(event);
    }
  }

  /**
   * Handle a click in the current document.
   *
   * @param  {MouseEvent} event Additional event arguments.
   * @return {boolean?}
   */
  handleClick (event) {
    const target = event.target;
    const closeOnClickOutside = this.options.closeOnClickOutside;

    const isButtonClick = this.button.contains(target);
    const isClickOutside = !this.content.contains(target);

    if (isButtonClick) {
      event.preventDefault();
      this.toggle();
      this.button.focus();
    } else if (isClickOutside && closeOnClickOutside && this.isExpanded) {
      this.collapse();
    }
  }

  /**
   * Handle a keyup event in the current window.
   *
   * @param  {KeyboardEvent} event Additional event arguments.
   * @return {boolean?}
   */
  handleKeyup (event) {
    const key = event.key;
    const closeOnEsc = this.options.closeOnEsc;

    const isEscapePressed = (key === 'Esc' || key === 'Escape');

    if (this.isExpanded && isEscapePressed && closeOnEsc) {
      event.preventDefault();
      this.button.focus();
      this.collapse();
    }
  }
}

/**
 * -----------------------------------------------------------------------------
 * Data API Implementation
 * -----------------------------------------------------------------------------
 */

document.querySelectorAll('[data-component~="DisclosureButton"]').forEach((element) => {
  const props = getPropsFromElement(element, {}, { prefix: 'data-disclosure-button-' });
  new DisclosureButton(element, props); // eslint-disable-line no-new
});

export { DisclosureButton };
