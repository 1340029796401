/**
 * Load the google analytics tag manager.
 *
 * @param {string} id
 * @returns {Promise<string>}
 */
export function loadGoogleTagManager(id) {
  return new Promise((resolve, reject) => {
    const now = new Date().getTime();

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({event:'gtm.js', 'gtm.start': now});

    const element = document.createElement('script');
    element.async = true;
    element.src = `https://www.googletagmanager.com/gtm.js?id=${id}`;


    element.onload = () => resolve(id);
    element.onerror = () => reject(id);

    document.body.appendChild(element);
  });
}
