import { ScrollTracker } from './ScrollTracker.js';

/**
 * A custom component that toggles HTML classes on user input.
 *
 * @version 1.1.0
 */
class ToggleController {
  /**
   * The default options used by the component.
   *
   * @return {Object}
   */
  static get Defaults () {
    return {};
  }

  /**
   * Create a new instance of the ToggleController.
   *
   * @param {Object} [options={}] Additional options.
   */
  constructor (options = {}) {
    this.options = Object.assign({}, ToggleController.Defaults, options);

    this.registry = options.registry || {};
    this.tracker = options.tracker || new ScrollTracker();

    this.handleClick = this.handleClick.bind(this);
    this.handleScroll = this.handleScroll.bind(this);

    this.bind();
  }

  /**
   * Register event listeners.
   *
   * @return {void}
   */
  bind () {
    document.addEventListener('click', this.handleClick);
    this.tracker.subscribe(this.handleScroll);
  }

  /**
   * Remove previously registered event listeners.
   *
   * @return {void}
   */
  unbind () {
    document.removeEventListener('click', this.handleClick);
    this.tracker.unsubscribe(this.handleScroll);
  }

  /**
   * Register a new element.
   *
   * @param  {string} event The name of the event.
   * @param  {HTMLElement} element The element.
   * @param  {string} className The HTML class to toggle.
   * @param  {Array} args Additional arguments.
   * @return {void}
   */
  register (event, element, className, args = []) {
    if (!this.registry[event]) {
      this.registry[event] = [];
    }

    this.registry[event].push([element, className, args]);
  }

  /**
   * Handle a click event.
   *
   * @param {MouseEvent} e Event arguments.
   * @return {void}
   */
  handleClick (e) {
    const entries = this.registry.click || [];
    const entry = entries.find(([element]) => element.contains(e.target));

    if (entry) {
      const [element, className] = entry;
      element.hidden = false;
      element.classList.toggle(className);
      e.preventDefault();
    }
  }

  /**
   * Handle a scroll event.
   *
   * @param  {Object} details An object holding scroll details.
   * @return {void}
   */
  handleScroll (details) {
    const entries = this.registry.scroll || [];
    const progress = details.progress * 100;

    entries.forEach(([element, className, [startY = 0, endY = 100]]) => {
      element.hidden = false;
      element.classList.toggle(className, (progress >= startY) && (progress <= endY));
    });
  }
}

/**
 * -----------------------------------------------------------------------------
 * Data API Implementation
 * -----------------------------------------------------------------------------
 */

const controller = new ToggleController();

document.querySelectorAll('[data-toggle-class]').forEach((element) => {
  const data = element.dataset.toggleClass;
  const options = (data.charAt(0) === '{')
    ? JSON.parse(data)
    : { click: data };

  element.hidden = true;
  element.classList.add('is-ready');

  for (const key in options) {
    const args = options[key];

    if (Array.isArray(args)) {
      controller.register(key, element, args[0], args[1] || []);
    } else {
      controller.register(key, element, args);
    }
  }
});

export {
  ToggleController,
  controller
};
