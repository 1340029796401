import {
  Loader
} from '@googlemaps/js-api-loader';

import {
  getPropsFromElement
} from '../utilities/dom';

/**
 * Base URL of the Google Map URL API.
 *
 * @var {string}
 */
const GOOGLE_DIRECTIONS_URL = 'https://www.google.com/maps/dir/';

/**
 * Geographic coordinates of the hanseatic city Bremen.
 *
 * @var {Object<string,number>}
 */
const CITY_BREMEN = {
  lat: 53.072270,
  lng: 8.810450
};

/**
 * Class name used to indicate a currently loading component.
 *
 * @var {string}
 */
const CLASS_NAME_LOADING = 'is-loading';

/**
 * Class name used to indicate a completely loaded component instance.
 *
 * @var {string}
 */
const CLASS_NAME_LOADED = 'is-loaded';

/**
 * Class name used to indicate a broken component instance.
 *
 * @var {string}
 */
const CLASS_NAME_FAILED = 'is-broken';

/**
 * A custom component that displays an interactive map using the Google Maps
 * JavaScript Library.
 *
 * @see {https://developers.google.com/maps/documentation/javascript/reference}
 */
class Map {
  /**
   * The default options used by the Map component.
   *
   * @return {Object}
   */
  static get Defaults () {
    return {
      center: CITY_BREMEN,
      zoom: 12,
      markers: []
    };
  };

  /**
   * Generate a Maps URL that can be used to request directions and launch
   * Google Maps from a device.
   *
   * @see {https://developers.google.com/maps/documentation/urls/get-started#directions-action}
   *
   * @param  {Object} item The item to generate the URL for.
   * @return {string}
   */
  static getDirectionsUrl (item) {
    return `${GOOGLE_DIRECTIONS_URL}?api=1&destination=${encodeURIComponent(item.address)}`;
  }

  /**
   * Generate the markup for an HTML link that used the Maps URL API to request
   * directions to the given location.
   *
   * @param  {Object} item The item to generate the URL for.
   * @return {string}
   */
  static getDirectionsLink (item) {
    return `<a href="${Map.getDirectionsUrl(item)}" class="c-button c-button--primary">Route planen</a>`;
  }

  /**
   * Create a new instance of the Map component.
   *
   * @param  {HTMLElement} element The element that should be used to embed a map.
   * @param  {Object} options Additional options.
   */
  constructor (element, options = {}) {
    this.element = element;

    this.markers = [];
    this.options = Object.assign({}, Map.Defaults, options);

    this.load()
      .then(this.handleLoad = this.handleLoad.bind(this))
      .catch(this.handleError = this.handleError.bind(this));
  }

  /**
   * Load the Google Maps JavaScript API.
   *
   * @return {Promise<google.maps.Map>}
   */
  load () {
    const loader = new Loader({
      apiKey: __GOOGLE_MAPS_API_KEY__,
      version: __GOOGLE_MAPS_API_VERSION__
    });

    this.element.classList.add(CLASS_NAME_LOADING);
    this.element.hidden = false;

    return loader
      .load()
      .then(() => {
        this.element.classList.remove(CLASS_NAME_LOADING);
        this.element.classList.add(CLASS_NAME_LOADED);
        this.map = new google.maps.Map(this.element, this.options);
        this.bounds = new google.maps.LatLngBounds();
        this.infoWindow = new google.maps.InfoWindow();
        return this.map;
      })
      .catch(() => {
        this.element.classList.remove(CLASS_NAME_LOADING);
        this.element.classList.add(CLASS_NAME_FAILED);
      });
  }

  /**
   * Setup the component instance.
   *
   * @return {void}
   */
  setup () {
    this.addMarkers(this.options.markers);

    if (this.markers.length > 1) {
      this.fitMarkers(this.markers);
    } else if (this.markers.length > 0) {
      this.map.setCenter(this.markers[0].getPosition());
    }
  }

  /**
   * Dispose the component instance.
   *
   * @return {void}
   */
  dispose () {
    this.element.classList.remove(CLASS_NAME_LOADED);
    this.element.classList.remove(CLASS_NAME_FAILED);

    this.element = null;
    this.bounds = null;
    this.map = null;
    this.infoWindow = null;
    this.markers = [];
  }

  /**
   * Add multiple markers to the current map instance.
   *
   * @param  {Object[]} items The markers to add to the map.
   * @return {self}
   */
  addMarkers (items) {
    items.forEach((item) => this.addMarker(item));
    return this;
  }

  /**
   * Add a marker to the current map instance.
   *
   * @param  {Object} item The marker to add.
   * @return {self}
   */
  addMarker (item) {
    const marker = new google.maps.Marker({
      title: item.title,
      position: item.position,
      animation: google.maps.Animation.DROP
    });

    marker.setMap(this.map);
    marker.addListener('click', this.handleMarkerClick.bind(this, item, marker));

    this.markers.push(marker);

    return this;
  }

  /**
   * Set the viewport to contain the given set of markers.
   *
   * @param {google.maps.Marker[]} markers The markers to fit in the viewport.
   * @return {self}
   */
  fitMarkers (markers) {
    const bounds = new google.maps.LatLngBounds();
    markers.forEach((marker) => bounds.extend(marker.getPosition()));
    this.map.fitBounds(bounds);
    return this;
  }

  /**
   * Handle the successfull loading of the Google Maps JavaScript API.
   *
   * @param {google.maps.Map} map The map instance.
   * @return {void}
   */
  handleLoad () {
    this.element.classList.remove(CLASS_NAME_LOADING);
    this.element.classList.add(CLASS_NAME_LOADED);

    this.setup();
  }

  /**
   * Handle a loading error.
   *
   * @param  {Error} e The error that occurred.
   * @return {void}
   */
  handleError () {
    this.element.classList.remove(CLASS_NAME_LOADING);
    this.element.classList.remove(CLASS_NAME_LOADED);

    this.element.classList.add(CLASS_NAME_FAILED);
  }

  /**
   * Handle a click on a marker.
   *
   * @param  {Object}             item   The location represented by the marker.
   * @param  {google.maps.Marker} marker The actual marker instance.
   * @return {void}
   */
  handleMarkerClick (item, marker) {
    const content = item.address
      ? `${item.content}${Map.getDirectionsLink(item)}`
      : `${item.content}`;

    if (!content) {
      return;
    }

    this.infoWindow.setContent(content);
    this.infoWindow.open(this.map, marker);
  }
}

/**
 * -----------------------------------------------------------------------------
 * Data API Implementation
 * -----------------------------------------------------------------------------
 */

document.querySelectorAll('[data-component~="Map"]').forEach((element) => {
  const props = getPropsFromElement(element, {}, { prefix: 'data-map-' });
  new Map(element, props); // eslint-disable-line no-new
});

export { Map };
