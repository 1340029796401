import CookieConsent from '@activescott/cookieconsent';

import {getCookie} from '../utilities/dom.js';
import {loadGoogleTagManager} from '../utilities/google.js';

const COOKIE_PREFIX = 'cookie_consent_status';
const COOKIE_ANALYTICS = `${COOKIE_PREFIX}_ANALYTICS`;

const ANSWER_ALLOW = 'ALLOW';
const ANSWER_DISMISS = 'DISMISS';

const banner = new CookieConsent({
  type: 'opt-in',
  layout: 'basic-header',
  position: 'center',
  cookie: {
    name: COOKIE_PREFIX,
    domain: window.location.hostname
  },
  content: {
    header: 'Cookie-Hinweis',
    message: 'Auf dieser Website werden Cookies gespeichert, um erweiterte Funktionen der Website sowie anonymisierte Analysen zu ermöglichen.',
    allow: 'Cookies erlauben',
    dismiss: 'Ablehnen',
    link: 'Datenschutzerklärung',
    href: '/datenschutz/',
    policy: 'Cookie-Richtlinie',
    target: false
  },
  palette: {
    popup: {
      text: '#0a0a0a',
      background: '#fff'
    },
    button: {
      text: '#fff',
      background: '#005ca3'
    }
  }
});

banner.on('initialized', () => {
  const value = getCookie(COOKIE_ANALYTICS);

  if (value === ANSWER_ALLOW) {
    loadGoogleTagManager(__GOOGLE_ANALYTICS_KEY__);
  }
});

banner.on('statusChanged', (cookie, value) => {
  if (
    (cookie === COOKIE_ANALYTICS) &&
    (value === ANSWER_ALLOW)
  ) {
    loadGoogleTagManager(__GOOGLE_ANALYTICS_KEY__);
  }
});

export {banner};
