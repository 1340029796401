import {
  getPropsFromElement,
  getTouchPosition
} from '../utilities/dom.js';

const CLASS_NAME_READY = 'is-ready';
const CLASS_NAME_ACTIVE = 'is-active';
const CLASS_NAME_CYCLING = 'is-cycling';
const CLASS_NAME_PAUSED = 'is-paused';
const CLASS_NAME_ENTER = 'a-enter';
const CLASS_NAME_LEAVE = 'a-leave';

const SELECTOR_FRAME = '.c-carousel__frame';
const SELECTOR_CONTROLS = '.c-carousel__controls';
const SELECTOR_SLIDE = '.c-carousel__slide';

const SELECTOR_PROGRESS = '.c-carousel__progress';
const SELECTOR_TOGGLE = '.c-carousel__control-toggle button';
const SELECTOR_PREV = '.c-carousel__control-prev button';
const SELECTOR_NEXT = '.c-carousel__control-next button';

/**
 * Utility function used to retrieve the current time in milliseconds.
 *
 * @return {number}
 */
const now = () => (new Date()).getTime();

/**
 * Determine whether the current user prefers reduced motion.
 *
 * @return {boolean}
 */
const prefersReducedMotion = () => (
  matchMedia('(prefers-reduced-motion: reduce)').matches
);

/**
 * An accessible carousel component that allows users to navigate through a
 * group of related slides.
 *
 * @see {@link https://w3c.github.io/aria-practices/#carousel Carousel}
 *
 * @version 1.0.0
 */
class Carousel {
  /**
   * Get the default options used by the Carousel component.
   *
   * @return {Object}
   */
  static get Defaults () {
    return {
      interval: 7,
      animate: true,
      cycle: true,
      mouse: true,
      keyboard: true,
      touch: true
    };
  };

  /**
   * Create a new instance of the Carousel component.
   *
   * @param  {HTMLElement} element The container holding the carousel items.
   * @param  {Object} options Additional options.
   */
  constructor (element, options = {}) {
    this.options = Object.assign({}, Carousel.Defaults, options);

    this.element = element;
    this.slides = [];
    this.current = -1;
    this.pointer = null;
    this.cycling = false;
    this.timer = null;
    this.time = now();
    this.remaining = 1000 * this.options.interval;

    this.setup();
    this.bind();

    if (this.slides.length > 0) {
      this.slides[this.current = 0].enable(false);
    }

    if (this.options.cycle) {
      this.resume();
    }
  }

  /**
   * Setup the component instance.
   *
   * @return {void}
   */
  setup () {
    const slides = this.element.querySelectorAll(SELECTOR_SLIDE);
    const elements = this.element.querySelectorAll('[hidden]');

    this.frame = this.element.querySelector(SELECTOR_FRAME);
    this.controls = this.element.querySelector(SELECTOR_CONTROLS);
    this.progress = this.element.querySelector(SELECTOR_PROGRESS);

    this.toggleButton = this.controls.querySelector(SELECTOR_TOGGLE);
    this.prevButton = this.controls.querySelector(SELECTOR_PREV);
    this.nextButton = this.controls.querySelector(SELECTOR_NEXT);

    this.element.classList.add(CLASS_NAME_READY);
    this.element.style.setProperty('--carousel-interval', `${this.options.interval}s`);

    this.frame.setAttribute('tabindex', 0);
    this.frame.setAttribute('aria-describedby', this.frame.getAttribute('data-describedby') || '');
    this.frame.removeAttribute('data-describedby');

    // Setup each individual slideshow.
    slides.forEach((element, index) => {
      const slide = new Slide(element, index, this);

      element.setAttribute('aria-posinset', index + 1);
      element.setAttribute('aria-setsize', slides.length);

      this.slides.push(slide);
    });

    // Show hidden controls and instructions.
    elements.forEach((element) => {
      if (!element.closest(SELECTOR_SLIDE)) {
        element.hidden = false;
      }
    });

    // Calculate the length of the progress circle, it has to be visible at this point.
    this.progress.setAttribute('stroke-dashoffset', Math.ceil(this.progress.getTotalLength()));
    this.progress.setAttribute('stroke-dasharray', Math.ceil(this.progress.getTotalLength()));
  }

  /**
   * Dispose the component instance.
   *
   * @return {void}
   */
  dispose () {
    this.slides.forEach((slide) => {
      slide.unbind();
      slide.dispose();
    });

    this.element = null;
    this.frame = null;
    this.controls = null;
    this.progress = null;

    this.toggleButton = null;
    this.prevButton = null;
    this.nextButton = null;

    this.slides = [];
    this.current = -1;
    this.pointer = null;

    this.cycling = false;
    this.time = 0;
    this.remaining = 0;

    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  }

  /**
   * Register event listeners.
   *
   * @return {void}
   */
  bind () {
    this.element.addEventListener('focusin', this.handleFocusIn = this.handleFocusIn.bind(this));
    this.element.addEventListener('focusout', this.handleFocusOut = this.handleFocusOut.bind(this));

    if (this.toggleButton) {
      this.toggleButton.addEventListener('click', this.handleToggleClick = this.handleToggleClick.bind(this));
    }

    if (this.prevButton) {
      this.prevButton.addEventListener('click', this.handlePrevClick = this.handlePrevClick.bind(this));
    }

    if (this.nextButton) {
      this.nextButton.addEventListener('click', this.handleNextClick = this.handleNextClick.bind(this));
    }

    if (this.options.mouse) {
      this.frame.addEventListener('wheel', this.handleWheelMove = this.handleWheelMove.bind(this));
    }

    if (this.options.touch) {
      this.frame.addEventListener('touchstart', this.handleTouchStart = this.handleTouchStart.bind(this), { capture: true, passive: true });
      this.frame.addEventListener('touchcancel', this.handleTouchCancel = this.handleTouchCancel.bind(this), { capture: true, passive: true });
    }

    if (this.options.keyboard) {
      this.frame.addEventListener('keydown', this.handleKeyDown = this.handleKeyDown.bind(this));
    }
  }

  /**
   * Remove previously registered event listeners.
   *
   * @return {void}
   */
  unbind () {
    this.element.removeEventListener('focusin', this.handleFocusIn);
    this.element.removeEventListener('focusout', this.handleFocusOut);

    if (this.options.mouse) {
      this.frame.removeEventListener('wheel', this.handleMouseWheel);
    }

    if (this.options.touch) {
      this.frame.removeEventListener('touchstart', this.handleTouchStart);
      this.frame.removeEventListener('touchcancel', this.handleTouchCancel);
    }

    if (this.options.keyboard) {
      this.frame.removeEventListener('keydown', this.handleKeyDown);
    }
  }

  /**
   * Pause the auto cycling of the current carousel.
   *
   * @return {void}
   */
  pause () {
    this.cycling = false;

    this.element.classList.remove(CLASS_NAME_CYCLING);
    this.element.classList.add(CLASS_NAME_PAUSED);
    this.progress.classList.remove(CLASS_NAME_ACTIVE);

    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
      this.remaining -= (now() - this.time);
    }

    this.toggleButton.setAttribute('aria-label', this.toggleButton.getAttribute('data-label-resume'));
  }

  /**
   * Resume the auto cycling of the current carousel.
   *
   * @return {void}
   */
  resume () {
    this.cycling = true;

    this.element.classList.remove(CLASS_NAME_PAUSED);
    this.element.classList.add(CLASS_NAME_CYCLING);
    this.progress.classList.add(CLASS_NAME_ACTIVE);

    this.time = now();
    this.timer = setTimeout(this.handleTimerTick.bind(this), Math.max(0, this.remaining));

    this.toggleButton.setAttribute('aria-label', this.toggleButton.getAttribute('data-label-pause'));
  }

  /**
   * Show the the first slide of the current carousel.
   *
   * @return {number}
   */
  first () {
    return this.goTo(0);
  }

  /**
   * Show the previous slide.
   *
   * @return {number}
   */
  previous () {
    return this.goTo(this.current - 1);
  }

  /**
   * Show the next slide.
   *
   * @return {number}
   */
  next () {
    return this.goTo(this.current + 1);
  }

  /**
   * Show the last slide of the current carousel.
   *
   * @return {number}
   */
  last () {
    return this.goTo(this.slides.length - 1);
  }

  /**
   * Show the given slide.
   *
   * @param  {number} index The index position of the slide to show.
   * @return {number}
   */
  goTo (index) {
    if (this.current !== index) {
      this.slides[this.current].disable(this.options.animate);
      this.current = (index + this.slides.length) % this.slides.length;
      this.slides[this.current].enable(this.options.animate);
    }
    return this.current;
  }

  /**
   * Handle an event which fires when an alement is about to receive focus.
   *
   * @param  {FocusEvent} e Additional event arguments.
   * @return {void}
   */
  handleFocusIn (e) {
    const isCurrentCarousel = this.element.contains(e.relatedTarget);
    const isToggleButton = (this.toggleButton.contains(e.relatedTarget));

    if (this.cycling && isCurrentCarousel && !isToggleButton) {
      this.pause();
    }

    this.frame.setAttribute('aria-live', 'polite');
  }

  /**
   * Handle an event which fires when an alement is about to lose focus.
   *
   * @param  {FocusEvent} e Additional event arguments.
   * @return {void}
   */
  handleFocusOut (e) {
    const isCurrentCarousel = e.relatedTarget && e.relatedTarget.contains(this.element);
    this.frame.setAttribute('aria-live', isCurrentCarousel ? 'polite' : 'off');
  }

  /**
   * Handle the press of a key.
   *
   * @param  {KeyboardEvent} e Additional event arguments.
   * @return {void}
   */
  handleKeyDown (e) {
    switch (e.key) {
      case 'Escape':
        this.cycling ? this.pause() : this.resume();
        break;
      case 'ArrowLeft':
      case 'ArrowUp':
        this.pause();
        this.previous();
        e.preventDefault();
        break;
      case 'ArrowRight':
      case 'ArrowDown':
        this.pause();
        this.next();
        e.preventDefault();
        break;
      case 'Home':
        this.pause();
        this.first();
        e.preventDefault();
        break;
      case 'End':
        this.pause();
        this.last();
        e.preventDefault();
        break;
    }
  }

  /**
   * Handle a click on the toggle button.
   *
   * @param  {MouseEvent} e Additional event arguments.
   * @return {void}
   */
  handleToggleClick () {
    if (this.cycling) {
      this.pause();
    } else {
      this.resume();
    }
  }

  /**
   * Handle a click on the previous button.
   *
   * @param  {MouseEvent} e Additional event arguments.
   * @return {void}
   */
  handlePrevClick () {
    this.pause();
    this.previous();
    this.prevButton.setAttribute('aria-controls', this.slides[this.current].id);
  }

  /**
   * Handle a click on the next button.
   *
   * @param  {MouseEvent} e Additional event arguments.
   * @return {void}
   */
  handleNextClick () {
    this.pause();
    this.next();
    this.nextButton.setAttribute('aria-controls', this.slides[this.current].id);
  }

  /**
   * Handle an elapsed timer.
   *
   * @return {void}
   */
  handleTimerTick () {
    this.next();

    this.time = now();
    this.remaining = 1000 * this.options.interval;
    this.timer = setTimeout(this.handleTimerTick.bind(this), this.remaining);
  }

  /**
   * Handle the beginning of a touch gesture.
   *
   * @param  {TouchEvent} e Additional event arguments.
   * @return {void}
   */
  handleTouchStart (e) {
    this.pointer = getTouchPosition(e);
    this.frame.addEventListener('touchend', this.handleTouchEnd = this.handleTouchEnd.bind(this));
  }

  /**
   * Handle the end of a touch gesture.
   *
   * @param  {TouchEvent} e Additional event arguments.
   * @return {void}
   */
  handleTouchEnd (e) {
    this.frame.removeEventListener('touchend', this.handleTouchEnd);

    const position = getTouchPosition(e);
    const x = position.x - this.pointer.x;
    const y = position.y - this.pointer.y;

    if ((x !== 0) && (Math.abs(x) > Math.abs(y))) {
      if (x < 0) {
        this.next();
      } else {
        this.previous();
      }
    }

    this.pointer = null;
  }

  /**
   * Handle the cancelation of a touch gesture.
   *
   * @return {void}
   */
  handleTouchCancel () {
    this.frame.removeEventListener('touchend', this.handleTouchEnd);
    this.pointer = null;
  }

  /**
   * Handle a user scrolling the mouse wheel.
   *
   * @param  {WheelEvent} e Additional event arguments.
   * @return {void}
   */
  handleWheelMove (e) {
    if (!e.altKey || !e.deltaY) {
      return;
    }

    // Debounce the execution of the carousel navigation.
    clearTimeout(this.scrolling);
    this.scrolling = setTimeout(this.handleWheelEnd.bind(this, e), 60);

    e.preventDefault();
  }

  /**
   * Handle the end of scrolling action.
   *
   * @param  {WheelEvent} e Additional event arguments.
   * @return {void}
   */
  handleWheelEnd (e) {
    if (Math.sign(e.deltaY) > 0) {
      this.next();
    } else {
      this.previous();
    }
    this.pause();
  }
}

/**
 * A data structure that represents an individual slide in a carousel component.
 *
 * @version 1.0.0
 */
class Slide {
  /**
   * Create a new instance of the Slide component.
   *
   * @param  {HTMLElement} element  The element representing a slide in the carousel.
   * @param  {number} index  The index position of the current slide.
   * @param  {Carousel} carousel The carousel the slide belongs to.
   */
  constructor (element, index, carousel) {
    this.element = element;
    this.index = index;
    this.carousel = carousel;

    this.billboard = this.element.children[0];
    this.id = this.billboard.id;

    this.setup();
    this.bind();
    this.disable(false);
  }

  /**
   * Setup the current carousel slide.
   *
   * @return {void}
   */
  setup () {
    this.control = this.carousel.controls.querySelector(`[aria-controls="${this.id}"]`);
    this.focusable = this.element.querySelectorAll('a, button, input');
  }

  /**
   * Release all resources used by the current slide.
   *
   * @return {void}
   */
  dispose () {
    this.id = null;
    this.index = -1;

    this.element = null;
    this.carousel = null;
    this.billboard = null;
    this.control = null;
    this.focusable = null;
  }

  /**
   * Register event listeners.
   *
   * @return {void}
   */
  bind () {
    this.element.addEventListener('animationend', this.handleAnimationEnd = this.handleAnimationEnd.bind(this));
    this.control.addEventListener('click', this.handleClick = this.handleClick.bind(this));
  }

  /**
   * Remove previously registered event listeners.
   *
   * @return {void}
   */
  unbind () {
    this.element.removeEventListener('click', this.handleAnimationEnd);
    this.control.removeEventListener('click', this.handleClick);
  }

  /**
   * Enable the current slide.
   *
   * @param {boolean} animate Whether the animate the entrance of the slide.
   * @return {void}
   */
  enable (animate = true) {
    if (animate) {
      this.element.classList.add(CLASS_NAME_ENTER);
    }

    this.element.classList.add(CLASS_NAME_ACTIVE);
    this.control.classList.add(CLASS_NAME_ACTIVE);

    this.element.setAttribute('aria-current', true);
    this.control.setAttribute('aria-disabled', true);
    this.control.setAttribute('aria-current', true);
    this.billboard.setAttribute('aria-hidden', false);

    this.focusable.forEach((element) => {
      element.removeAttribute('tabindex');
    });
  }

  /**
   * Disable the current slide.
   *
   * @param {boolean} animate Whether the animate the exit of the slide.
   * @return {void}
   */
  disable (animate = true) {
    if (animate) {
      this.element.classList.add(CLASS_NAME_LEAVE);
    }

    this.element.classList.remove(CLASS_NAME_ACTIVE);
    this.control.classList.remove(CLASS_NAME_ACTIVE);

    this.element.setAttribute('aria-current', false);
    this.control.removeAttribute('aria-disabled');
    this.control.setAttribute('aria-current', true);
    this.billboard.setAttribute('aria-hidden', true);

    this.focusable.forEach((element) => {
      element.setAttribute('tabindex', -1);
    });
  }

  /**
   * Handle a click on the control of the current slide.
   *
   * @return {void}
   */
  handleClick () {
    this.carousel.pause();
    this.carousel.goTo(this.index);
  }

  /**
   * Handle the end of an animation.
   *
   * @param  {AnimationEvent} e Additional event arguments.
   * @return {void}
   */
  handleAnimationEnd (e) {
    const slide = e.target;
    const enter = !!e.animationName.match(/(^|\W)in(\W|$)/);

    if (slide === this.element) {
      slide.classList.remove(enter ? CLASS_NAME_ENTER : CLASS_NAME_LEAVE);
      slide.setAttribute('aria-current', enter);
    }
  }
}

/**
 * -----------------------------------------------------------------------------
 * Data API Implementation
 * -----------------------------------------------------------------------------
 */

document.querySelectorAll('[data-component~="Carousel"]').forEach((element) => {
  const props = getPropsFromElement(element, {}, { prefix: 'data-carousel-' });
  const options = Object.assign(props, { animate: !prefersReducedMotion() });
  new Carousel(element, options); // eslint-disable-line no-new
});

export { Carousel, Slide };
