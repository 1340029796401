import camelCase from 'just-camel-case';

const root = document.documentElement;
const body = document.body;

/**
 * Determine whether the given element is the document node.
 *
 * @param  {*} element The element to test.
 * @return {boolean}
 */
export function isDocument (element) {
  return (typeof element === 'object') && (element.nodeType === 9);
}

/**
 * Determine whether the given element is the window element.
 *
 * @param  {*} element The element to test.
 * @return {boolean}
 */
export function isWindow (element) {
  return (typeof element === 'object') && isDocument(element.document);
}

/**
 * Get the value of a cookie.
 *
 * @param {string} name The name of the cookie.
 * @returns {?string} The value of the cookie.
 */
export function getCookie(name) {
  var value = '; ' + document.cookie;
  var parts = value.split('; ' + name + '=');
  return (parts.length == 2) ? parts.pop().split(';').shift() : null;
}

/**
 * Get the properties from a host element’s data attributes.
 *
 * @param  {HTMLElement} element The host element.
 * @param  {Object} defaults Default values to be passed to the component.
 * @param  {Object} options Additional options.
 * @return {Object}
 */
export function getPropsFromElement (element, defaults = {}, options = {}) {
  const attrs = element.attributes;
  const props = Object.assign({}, defaults);
  const scripts = element.querySelectorAll('script[type="application/json"]');

  // Provide defaults for the user options.
  options = Object.assign({}, { prefix: 'data-', exclude: [] }, options);

  // Collect properties of a component from script elements containing JSON data.
  for (let i = scripts.length - 1; i >= 0; i--) {
    Object.assign(props, JSON.parse(scripts[i].innerHTML));
  }

  // Collect properties of a component from the host’s attributes collection.
  for (let i = attrs.length - 1; i >= 0; i--) {
    const attrName = attrs[i].name;
    const attrValue = attrs[i].nodeValue;

    if (!attrName || typeof attrName !== 'string') {
      continue;
    }

    const propName = camelCase(attrName.split(options.prefix).pop() || '');
    props[propName] = attrValue;
  }

  // Remove undesired properties.
  for (let i = options.exclude.length - 1; i >= 0; i--) {
    const attrName = options.exclude[i];
    const propName = camelCase(attrName);

    delete props[propName];
  }

  return props;
}

/**
 * Get the absolute position of the first touch pointer.
 *
 * @param  {TouchEvent} e The touch event.
 * @return {Object<string,number>}
 */
export function getTouchPosition (e) {
  const pointer = e.changedTouches && e.changedTouches[0] ? e.changedTouches[0] : e;
  return {
    x: Math.round(pointer.clientX || pointer.pageX || pointer.x),
    y: Math.round(pointer.clientY || pointer.pageY || pointer.y)
  };
}

/**
 * Get the height of an element’s content, including content not visible on the
 * screen due to overflow.
 *
 * @param  {Element} element The element to inspect.
 * @return {number}
 */
export function getScrollHeight (element) {
  if (isWindow(element)) {
    return Math.max(
      body.scrollHeight,
      root.scrollHeight,
      body.offsetHeight,
      root.offsetHeight,
      body.clientHeight,
      root.clientHeight
    );
  }

  return Math.max(
    element.scrollHeight,
    element.offsetHeight,
    element.clientHeight
  );
}

/**
 * Get the height of an element, including the vertical padding and borders.
 *
 * @param  {Element} element The element to inspect.
 * @return {number}
 */
export function getHeight (element) {
  if (isWindow(element)) {
    return element.innerHeight || root.clientHeight || body.clientHeight;
  }

  return Math.max(element.offsetHeight, element.clientHeight);
}

/**
 * Get the number of pixels that an element’s content is scrolled.
 *
 * @param  {Element} element The element to inspect.
 * @return {boolean}
 */
export function getScrollY (element) {
  if (isWindow(element)) {
    return (element.pageYOffset !== undefined)
      ? window.pageYOffset
      : (root || body.parentNode || body).scrollTop;
  }

  return element.scrollTop;
}

/**
 * Create a debounced function that delays invoking func until the next
 * repaint.
 *
 * @param  {Function} fn The function to debounce.
 * @return {Function}
 */
export function debounce (fn) {
  let frame;

  return (...params) => {
    if (frame) {
      cancelAnimationFrame(frame);
    }

    frame = requestAnimationFrame(() => fn(...params));
  };
}
