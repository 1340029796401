import {
  getHeight,
  getScrollHeight,
  getScrollY,
  debounce
} from '../utilities/dom.js';

export const DIRECTION_UP = 'up';
export const DIRECTION_DOWN = 'down';

/**
 * A custom component that tracks a users scroll position efficiently.
 *
 * @version 1.1.0
 */
export class ScrollTracker {
  /**
   * The default options used by the component.
   *
   * @return {Object}
   */
  static get Defaults () {
    return {
      offset: {
        [DIRECTION_UP]: 0,
        [DIRECTION_DOWN]: 0
      }
    };
  }

  /**
   * Create a new instance of the ScrollTracker component.
   *
   * @param {HTMLElement} [element=window] The element listening for the scroll event.
   * @param {Object} [options={}] Additional options.
   */
  constructor (element = window, options = {}) {
    this.element = element;
    this.options = Object.assign({}, ScrollTracker.Defaults, options);

    this.state = {};
    this.handlers = [];
    this.locked = false;
    this.lastScrollY = 0;

    this.update = this.update.bind(this);
    this.handleScroll = debounce(this.handleScroll.bind(this));

    this.bind();
  }

  /**
   * Register event listeners.
   *
   * @return {void}
   */
  bind () {
    this.element.addEventListener('scroll', this.handleScroll, { passive: true, capture: false });
  }

  /**
   * Remove previously registered event listeners.
   *
   * @return {void}
   */
  unbind () {
    cancelAnimationFrame(this.frame);
    this.element.removeEventListener('scroll', this.handleScroll, { passive: true, capture: false });
  }

  /**
   * Subscribe for updates of the users scroll position.
   *
   * @param  {Function} handler The event handler to notify.
   * @return {void}
   */
  subscribe (handler) {
    this.handlers.push(handler);
  }

  /**
   * Remove the given event handler from the queue.
   *
   * @param  {Function} handler The event handler to remove.
   * @return {void}
   */
  unsubscribe (handler) {
    const index = this.handlers.indexOf(handler);

    if (index !== -1) {
      this.handlers.splice(index, 1);
    }
  }

  /**
   * Notify all registered event listeners of the current component.
   *
   * @param  {Object} state An object that captures the current scroll position.
   * @return {void}
   */
  publish (state) {
    this.handlers.forEach((handler) => handler(state));
  }

  /**
   * Force an update of the current scroll position.
   *
   * @return {void}
   */
  update () {
    const scrollY = Math.round(getScrollY(this.element));
    const scrollHeight = getScrollHeight(this.element);
    const height = getHeight(this.element);

    const offset = this.options.offset;
    const lastScrollY = this.lastScrollY;

    this.state.scrollY = scrollY;
    this.state.lastScrollY = lastScrollY;

    this.state.distance = Math.abs(scrollY - lastScrollY);
    this.state.direction = scrollY > lastScrollY ? DIRECTION_DOWN : DIRECTION_UP;
    this.state.progress = scrollY / (scrollHeight - height);

    this.state.top = scrollY <= offset[this.state.direction];
    this.state.bottom = (scrollY + height) >= scrollHeight;

    this.publish(this.state);

    this.lastScrollY = scrollY;
    this.locked = false;
  }

  /**
   * Handle the scroll event.
   *
   * @return {void}
   */
  handleScroll () {
    if (!this.locked) {
      this.locked = true;
      this.frame = requestAnimationFrame(this.update);
    }
  }
}
