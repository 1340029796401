import {
  getPropsFromElement
} from '../utilities/dom.js';

const START_Y_MIN = 4;
const START_Y_MAX = 12;

const END_Y_MIN = 4;
const END_Y_MAX = 12;

const CURVE_X_MIN = 16;
const CURVE_Y_MIN = 8;

/**
 * A custom component that renders a text underline using a bèzier curve.
 *
 * @version 1.0.0
 */
class TextUnderline {
  /**
   * Create the SVG image representing the text underline.
   *
   * @param {number} width The width of text text underline.
   * @param {number} [thickness=1] The thickness of the text underline.
   * @param {string} [color='currentColor'] The color of the text underline.
   * @return {SVGSVGElement}
   */
  static createSVG (width, thickness = 1, color = 'currentColor') {
    const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');

    svg.setAttribute('viewBox', `0 0 ${width} 32`);
    svg.setAttribute('width', width);
    svg.setAttribute('height', 32);
    svg.setAttribute('role', 'presentation');
    svg.setAttribute('focusable', false);
    svg.setAttribute('aria-hidden', true);

    svg.appendChild(TextUnderline.createPath(width, thickness, color));

    return svg;
  }

  /**
   * Generate a random path element that that can be used to draw the text
   * underline.
   *
   * @param {number} width The width of text text underline.
   * @param {number} [thickness=1] The thickness of the text underline.
   * @param {string} [color='currentColor'] The color of the text underline.
   * @return {SVGPathElement}
   */
  static createPath (width, thickness = 1, color = 'currentColor') {
    const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');

    path.setAttribute('d', TextUnderline.createQuadraticBezierCurve(width));
    path.setAttribute('fill', 'transparent');
    path.setAttribute('stroke', color);
    path.setAttribute('stroke-width', thickness);
    path.setAttribute('stroke-linecap', 'round');
    path.setAttribute('stroke-dasharray', Math.ceil(path.getTotalLength()));
    path.setAttribute('stroke-dashoffset', Math.ceil(path.getTotalLength()));

    return path;
  }

  /**
   * Generate the path used to draw a quadratic bézier curve.
   *
   * @param  {number} width The maximum width of the curve.
   * @param {number} offset A numerical offset of the start/end points.
   * @return {string}
   */
  static createQuadraticBezierCurve (width, offset = 0) {
    const startX = offset;
    const startY = Math.floor(Math.random() * (START_Y_MAX - START_Y_MIN)) + START_Y_MIN;

    const endX = width - offset;
    const endY = Math.floor(Math.random() * (END_Y_MAX - END_Y_MIN)) + END_Y_MIN;

    const controlX = Math.floor(Math.random() * (width - CURVE_X_MIN)) + CURVE_X_MIN;
    const controlY = Math.floor(Math.random() * (0.12 * width - CURVE_Y_MIN)) + CURVE_Y_MIN;

    return `M ${startX},${startY} Q ${controlX},${controlY} ${endX},${endY}`;
  }

  /**
   * The default options used by the TextUnderline component.
   *
   * @return {Object}
   */
  static get Defaults () {
    return {
      visible: true,
      thickness: 4,
      color: '#f3da4f'
    };
  };

  /**
   * Create a new instance of the TextUnderline component.
   *
   * @param  {HTMLElement} element The text element that should be underlined.
   * @param  {Object} options Additional options that control the appearance of the text underline.
   */
  constructor (element, options = {}) {
    this.element = element;
    this.options = Object.assign({}, TextUnderline.Defaults, options);

    this.setup();
  }

  /**
   * Determine whether text underline is currently visible.
   *
   * @return {boolean}
   */
  get isVisible () {
    return this.element.classList.contains('with-text-underline');
  }

  /**
   * Setup the component instance.
   *
   * @return {void}
   */
  setup () {
    this.svg = TextUnderline.createSVG(this.element.offsetWidth, this.options.thickness, this.options.color);
    this.element.appendChild(this.svg);

    this.path = this.svg.querySelector('path');
    this.length = Math.ceil(this.path.getTotalLength());

    if (this.options.visible) {
      this.show();
    } else {
      this.hide();
    }
  }

  /**
   * Dispose the component instance.
   *
   * @return {void}
   */
  dispose () {
    this.svg.remove();

    this.svg = null;
    this.path = null;
    this.length = 0;
  }

  /**
   * Show the text underline.
   *
   * @return {void}
   */
  show () {
    this.element.classList.add('with-text-underline');
    this.path.setAttribute('stroke-dashoffset', 0);
  }

  /**
   * Hide the text underline.
   *
   * @return {void}
   */
  hide () {
    this.element.classList.remove('with-text-underline');
    this.path.setAttribute('stroke-dashoffset', this.length);
  }
}

/**
 * -----------------------------------------------------------------------------
 * Data API Implementation
 * -----------------------------------------------------------------------------
 */

document.querySelectorAll('[data-component~="TextUnderline"]').forEach((element) => {
  const props = getPropsFromElement(element, {}, { prefix: 'data-text-underline-' });
  new TextUnderline(element, props); // eslint-disable-line no-new
});

export { TextUnderline };
