import {
  getPropsFromElement
} from '../utilities/dom.js';

import {
  Map
} from './Map.js';

const store = window.localStorage || {};

const CLASS_NAME_READY = 'is-ready';
const CLASS_NAME_ACTIVATED = 'is-activated';

const SELECTOR_BUTTON = '.js-embed-activate';
const SELECTOR_CHECKBOX = '.js-embed-remember';
const SELECTOR_MAP = '.c-map';

class EmbeddedContent {
  /**
   * The default options used by the Embed component.
   *
   * @return {Object}
   */
  static get Defaults () {
    return {
      services: {
        map: (container) => {
          const element = container.querySelector(SELECTOR_MAP);
          const props = getPropsFromElement(element, {}, { prefix: 'data-map-' });
          return new Map(element, props);
        }
      }
    };
  };

  /**
   * Get a list of services the user agreed to embed in the current document.
   *
   * @return {string}
   */
  static get ActivatedServices () {
    return JSON.parse(store.embedActivatedServices || '[]');
  }

  /**
   * Accept to embed a specific service on page load.
   *
   * @param  {string} service The service to embed on page load.
   * @return {void}
   */
  static allow (service) {
    const services = EmbeddedContent.ActivatedServices;

    if (services.indexOf(service) === -1) {
      store.embedActivatedServices = JSON.stringify(services.concat([service]));
    }
  }

  /**
   * Create a new instance of the Embed component.
   *
   * @param  {HTMLElement} element The element that should be used as a container for the embedded content.
   * @param  {Object} options Additional options.
   */
  constructor (element, options = {}) {
    this.options = Object.assign({}, EmbeddedContent.Defaults, options);

    this.element = element;
    this.button = this.element.querySelector(SELECTOR_BUTTON);
    this.checkbox = this.element.querySelector(SELECTOR_CHECKBOX);

    this.service = this.element.hasAttribute('data-embed-service')
      ? this.element.getAttribute('data-embed-service').toLowerCase()
      : null;

    if (!this.service || !(this.service in EmbeddedContent.Defaults.services)) {
      throw new Error('Embed: Invalid service type');
    }

    this.setup();
    this.bind();

    if (this.isActivated) {
      this.activate();
    }
  }

  /**
   * Determine whether the user has previously accepted embedding of the current
   * service type.
   *
   * @return {boolean}
   */
  get isActivated () {
    return EmbeddedContent.ActivatedServices.indexOf(this.service) !== -1;
  }

  /**
   * Determine whether the answer of the user should be remembered.
   *
   * @return {boolean}
   */
  get shouldRemember () {
    return this.checkbox && this.checkbox.checked;
  }

  /**
   * Setup the component instance.
   *
   * @return {void}
   */
  setup () {
    this.element.hidden = false;
    this.element.classList.add(CLASS_NAME_READY);
  }

  /**
   * Dispose the component instance.
   *
   * @return {void}
   */
  dispose () {
    if (this.component && this.component.dispose) {
      this.component.dispose();
    }

    this.component = null;
    this.element = null;
    this.container = null;
    this.button = null;
    this.checkbox = null;
  }

  /**
   * Register event listeners.
   *
   * @return {void}
   */
  bind () {
    if (this.button) {
      this.button.addEventListener('click', this);
    }
  }

  /**
   * Remove previously registered event listeners.
   *
   * @return {void}
   */
  unbind () {
    if (this.button) {
      this.button.removeEventListener('click', this);
    }
  }

  /**
   * Load the embedded content.
   *
   * @return {void}
   */
  activate () {
    // Hide the content of the current component.
    for (let i = this.element.children.length - 1; i >= 0; i--) {
      this.element.children[i].hidden = true;
    }

    // Attach the initialized component to the current document.
    this.element.classList.add(CLASS_NAME_ACTIVATED);
    this.element.hidden = false;

    // Create the specified service.
    const factory = this.options.services[this.service];
    this.component = factory(this.element);

    // Remember the users decision for subsequent requests.
    if (this.shouldRemember && !this.isActivated) {
      EmbeddedContent.allow(this.service);
    }
  }

  /**
   * Handle a click event on the corresponding button.
   *
   * @param  {MouseEvent} e Additional event arguments.
   * @return {void}
   */
  handleEvent (e) {
    this.unbind();
    this.activate();
    e.preventDefault();
  }
}

/**
 * -----------------------------------------------------------------------------
 * Data API Implementation
 * -----------------------------------------------------------------------------
 */

document.querySelectorAll('[data-component~="Embed"]').forEach((element) => {
  new EmbeddedContent(element); // eslint-disable-line no-new
});

export { EmbeddedContent };
